
.app-footer {
  min-height: 25px;
  width: 100%;
  background-color: #000;
  color: #fff;
  vertical-align: middle;
  text-align: center;
  padding: 10px ;
  position: absolute;
  bottom: -60px;
}

.app-footer div{
  display: block;
}
.app-footer a{
  color: #fff;
  margin: 20px;
  padding: 10px;
  text-decoration: none;
  font-size: 14px;
}
.app-footer .social img{
  height: 30px;
  margin: 10px 0px 10px 0px;
}

@media screen and (max-width: 485px) {

  .app-footer {
    bottom: -80px;
  }
  .app-footer a{
    margin: 10px;
    padding: 5px;
  }

  .app-footer .social img{
    height: 20px;
    margin: 10px 0px 10px 0px;
  }

  .app-footer .copyright {
    font-size: 12px;
  }
}
