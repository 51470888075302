.App {
  text-align: center;
}

.search[type=text] {
  /*background-color: #9476f7;*/
  background-image: url('https://cdn.bitdegree.org/learn/search-icon.png?229dfb2d');
  background-size: 18px;
  background-position: 2px 5px;
  background-repeat: no-repeat;
  padding-left: 22px;
  height: 27px;
  border-radius: 5px;
  border: 1px solid lightgray;
  font-size: 13px;
}