
.app-header {
  height: 30px;
  width: 99.5%;
  background-color: #000;
  color: #fff;
  vertical-align: middle;
  text-align: center;
  padding: 10px ;
}

.app-header.no-data input[type=text] {
  border: 1px solid #000;
  position: relative;
  top: 9em;
  z-index: 200;
  width: 400px;
  font-size: 16px;
  height: 40px;

  background-image: url('https://cdn.bitdegree.org/learn/search-icon.png?229dfb2d');
  background-size: 20px;
  background-position: 8px 10px;
  background-repeat: no-repeat;
  padding-left: 40px;
  border-radius: 5px;
}

.app-header .logo-container {
  display: inline-block;
  width: 19%;
  height: 100%;
  text-align: left;
  font-size: 25px;
  vertical-align: middle;
}
.app-header.no-data .logo-container {
  display: block;
  width: 99%;
  text-align: center;
}
.app-header.no-data .action-container {
  display: block;
  width: 99%;
  text-align: center;
}
.app-header .action-container {
  display: inline-block;
  width: 80%;
  text-align: right;
}

.action-container input[type=text] {
  width: 200px
}

.app-header .logo-container .product-name {
  display: inline-block;
  vertical-align: top;
}
.app-header .logo-container img {
  height: 30px;
}

@media screen and (max-width: 485px) {

  .app-header {
    width: 97.5%;
    padding: 5px 5px 5px 6px;
  }

  .app-header.no-data {
    padding: 5px 5px 4px 6px;
    height: 25px;
  }

  .app-header.data .action-container input[type=text] {
    width: 80px;
    height: 24px;
  }

  .app-header.no-data .action-container input[type=text] {
    width: 200px;
  }

  .app-header.no-data input[type=text] {
    width: 200px;
    font-size: 12px;
  }

  .app-header.data .logo-container {
    padding: 2px;
  }
  .app-header.no-data .logo-container {
    font-size: 20px;
  }

  .app-header.data .logo-container img {
    height: 20px;
  }
  .app-header.no-data .logo-container img {
    height: 25px;
  }

  .app-header.data .action-container {
    width: 55%;
  }
  .app-header.data .logo-container {
    width: 42%;
    font-size: 18px;
  }
}