
.list-container .filter-masjid {
  height: 32px;
  width: 100%;
  background-color: #3498db;
  padding: 6px 0px;
}
.list-container input[type=text] {
  width: 80%
}
.list-container{
  width: 29.8%;
  height: 90vh;
  display: inline-block;
  vertical-align: top;
  overflow: scroll;
  position: relative;
}

.list-container.empty {
  width: 0%;
}
.list-container .list-item {
  padding: 10px;
  background-color: #ecf0f5;
  border-bottom: 1px solid lightgray;
  min-height: 50px;
  color: #000;
  text-align: left;
  font-size: 12px;
  vertical-align: middle;
}
.list-container .list-item.hide {
  display: none;
}
.list-container .list-item.hover,
.list-container .list-item:hover {
  background-color: #d9dde2;
}

.list-container .list-item .title {
  vertical-align: middle;
}
.list-container .list-item .title .label {
  font-size: 18px;
  padding: 10px 5px;
  display: inline-block;
  vertical-align: middle;
}
.list-container .list-item .title .logo {
  display: inline-block;
  vertical-align: middle;
}
.list-container .list-item .logo img {
  max-height: 25px;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 2px;
  background-color: #fff;
}

.list-container .list-item .web-url {
  display: inline-block;
}
.list-container .list-item .phone-number {
  display: inline-block;
}

@media screen and (max-width: 485px) {

  .list-container {
    width: 100%;
    height: 25vh;
  }

  .list-container .list-item {
    display: inline-block;
    width: 43%;
    border: 1px solid #000;
    margin: 5px;
    border-radius: 10px;
    padding: 5px;
    min-height: 115px;
  }
  .list-container .list-item .title .logo {
    display: none;
  }
  .list-container .list-item .title .label {
    font-size: 16px;
    padding: 5px 0px;
  }

  .list-container .filter-masjid .search {
    width: 90%;
  }
}