.map-container {
  width: 70%;
  display: inline-block;
  border-left: 1px solid #000;
}

.map-container.full {
  width: 99%;
}

.map-container .map-view {
  height: 90vh;
  width: 100%;
  position: relative;
  padding: 0;
  margin: 0;
}

.masjid-marker {
  background-repeat: no-repeat;
  background-color: transparent; /* Used if the image is unavailable */
  height: 20px; /* You must set a specified height */
  width: 10px;
  background-position: center; /* Center the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  position: absolute;
  left:3px;
  top:-10px;
  padding: 8px;
  transition: ease-in;
}
.masjid-marker.hide {
  display: none;
}

.masjid-marker:hover {
  height: 30px; /* You must set a specified height */
  width: 15px;
  transition: ease-in;
  transition-duration: .1s;
}
.support-masjid {
  background-image: url('../assets/marker/mrk.png');
}

.unsupport-masjid {
  background-image: url('../assets/marker/unsupported_mrk.png')
}

.masjid-data .name{
  display: block;
  padding-bottom: 5px;
}
.masjid-data {
  font-size: 11px;
  margin: 3px;
  //border: 1px solid lightgray;
  border-radius:1px;
}

.masjid-data table, th, td {
  border: 1px solid lightgray;
  margin: 0px;
  padding: 0px;
}
.masjid-data table{
  border-collapse: collapse;
}

.masjid-data .info, .masjid-data .ng-binding {
  width: 55px;
}
.masjid-data .info {
  background-color: #d9edf7;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 220px;
  background-color: white;
  border: 1px solid #000;
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 2000;
  bottom: 110%;
  left: -110%;
  margin-left: -60px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -6px;
  border-width: 5px;
  border-style: solid;
  border-color: #000 transparent transparent transparent;
}

.tooltip:hover .tooltiptext, .showMarker .tooltiptext {
  visibility: visible;
}

@media screen and (max-width: 485px) {
  .map-container {
    width: 100%;
  }

  .map-container.markers .map-view {
   height: 65vh;
  }

}